/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { AdSlotRow } from "../collection-templates/ad-slot-row";
import { CustomWidgetRow } from "../collection-templates/custom-widget-row";
import { AdWrapper } from "../ad-wrapper";
import { dfpAdConfig } from "../../dfp-ad-config";

const StoryRowWrapper = ({ rows, storyId, children, isStoryInfiniteScrollEnabled }) => {
  if (!rows.length) {
    rows = [
      {
        rowId: 1603877816292,
        type: "story",
        config: {},
        pageType: "story"
      }
    ];
  }

  return rows.map(row => {
    if (!global.qtLoadedFromShell) {
      switch (row.type) {
        case "widget":
          return <div id={`widget-${row.rowId}_${storyId}`}><CustomWidgetRow storyId={storyId} metadata={row} key={row.rowId} /></div>;
        case "ads":
          return (
            <div id={`ad-${row.rowId}_${storyId}`}>
              <AdSlotRow metadata={{...row, ...{storyId, isStoryInfiniteScrollEnabled}}} key={row.rowId} />
            </div>
          );
        case "story":
          return children;
      }
    }


    switch (row.type) {
      case "widget":
        return <CustomWidgetRow metadata={row}   key={row.rowId} />;
      case "ads":
        return <AdSlotRow metadata={{...row, ...{storyId, isStoryInfiniteScrollEnabled}}} key={row.rowId}/>;
      case "story":
        return children;
    }
  });
};

export default StoryRowWrapper;
