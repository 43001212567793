/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

import React from "react";
import { StoryWrapper } from "../story-wrapper";

export const TextStory = props => {
  return <StoryWrapper {...props} />;
};

TextStory.defaultProps = {
  storyStoreField: "story",
  storyPageType: "text-story"
};
export default TextStory;
