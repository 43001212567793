/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import Story from "./story";
import StoryRowWrapper from "../story-row-wrapper.js";
import { useSelector } from "react-redux";

export const StoryWrapper = (props) => {
  const { config, storyStoreField, storyPageType } = props;
  const storyRows = get(config, ["pagebuilder-config", storyStoreField, storyPageType, "rows"], []);
  const pbConfig = get(config, ["pagebuilder-config"], {});
  const isStoryInfiniteScrollEnabled =
  get(pbConfig, ["infiniteScroll", "infiniteScrollType"], "disabled") !== "disabled";
  const storyId = get(props, ["story", "id"], "");
  const enableDarkMode = useSelector((state) => get(state, ["header", "isDarkModeEnabled"], false));
  const updateProps = { ...props, enableDarkMode };
  return (
    <StoryRowWrapper rows={storyRows} storyId={storyId} isStoryInfiniteScrollEnabled={isStoryInfiniteScrollEnabled}>
      <Story {...updateProps} />
    </StoryRowWrapper>
  );
};

StoryWrapper.propTypes = {
  config: PropTypes.object,
  story: PropTypes.object,
  storyStoreField: PropTypes.string,
  storyPageType: PropTypes.string,
  storyBaseType: PropTypes.string,
};

StoryWrapper.defaultProps = {
  storyStoreField: "story",
  storyPageType: "text-story",
};
export default StoryWrapper;
